<template>
  <div class="assigned-task">
    <a-list
      class="demo-loadmore-list"
      :loading="loading"
      item-layout="horizontal"
      :data-source="assignedTaskList"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <template #actions>
            <a-button
              danger
              size="small"
              @click="$emit('unassignTask', item.id)"
              >Unassign</a-button
            >
          </template>
          <a-list-item-meta :description="item.taskName"> </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>
<script>
export default {
  props: ['loading', 'assignedTaskList'],
  emits: ['unassignTask'],
};
</script>
<style scoped>
.assigned-task {
  height: 300px;
  overflow: auto;
}
</style>
